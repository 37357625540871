* {
  margin: 0;
  font-family: 'Quicksand', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  width: 100%;
  height: 100%;
}

.text-center {
  text-align: center;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.bold {
  font-weight: 600;
}

.fs20 {
  font-size: 20px;
}

.btn-r {
  border-radius: 50px !important;
}

.DialogTitlePR {
  padding-right: 50px !important;
}

.MuiFormLabel-root {
  font-weight: 500 !important;
}

.Former {
  .MuiFormHelperText-root {
    margin-left: 0;
  }
}

td.MuiTableCell-root {
  font-size: 16px;
}

@import 'react-big-calendar/lib/css/react-big-calendar.css';
//@import 'react-phone-input-material-ui/lib/style.css';
